import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Spinner, Table, Modal, Form, Button, Alert, Row, Col } from "react-bootstrap";
import { useHistory, Route, Link, useParams} from "react-router-dom";
import classnames from "classnames";

import axios from "axios";
import React from "react";

import parse from 'html-react-parser';



function UserApplication(props) {

  const history = useHistory();
    // State Management
    const [isLoading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [applicationData, setApplicationData] = useState({});
    const [message, setMessage] = useState();
    const [error, setError] = useState({});
    const [tag, setTag] = useState(0);
    const appID = useParams().appID;
    let preview = false;
    if(history.location.state){
      preview = history.location.state.preview;
    }

    const generateTag = (tags) => {
      let t = Math.floor((10000 + Math.random() * 90000));
        while(tags.findIndex(element => element.tag == t) != -1){
          t = Math.floor((10000 + Math.random() * 90000));
          console.log(t);
        }
        return t;
    }

    const [answers, setAnswers] = useState([{answer: ""}]);
    useEffect(() => {
      axios.get("/applications/getTags", {
        params:{
          appID: appID,
        }
      })
      .then((res) => {

        let t = generateTag(res.data)
        setTag(t);

      })

      axios.get("/users/getUserPoints", {
        params:{
          userID: props.auth.user.id,
        },
      })
        .then((res) => {
          //console.log(res);
          setIsAdmin(res.data[0].role == "admin");
        })
      
      axios.get("/applications/getApplicationById", {
        params:
          {
            userID: props.auth.user.id,
            appID: appID,
          }
      })
          .then((res) => {
              setApplicationData(res.data[0]);
              addAnswer(res.data[0].questions.length);
              setLoading(false);
          })
          .catch((err) => console.log(err));

    }, []);

    const addAnswer = (k) => {
      for(let i = 0; i < k-1; i++){
        answers.push({answer: "", wordCount:0});
      }
    }

    const onSubmit = (e) => {
      let ans = window.confirm("Are you sure you want to submit application? You cannot edit once submitted.");
      if(!ans){
        return;
      }
      e.preventDefault();
      
      for(let k = 0; k < answers.length; k++){
        if(applicationData.questions[k].minWords){
          if(answers[k].wordCount < applicationData.questions[k].minWords){
            setMessage({
              variant: "danger",
              data: `Please write at least ${applicationData.questions[k].minWords} words for question ${k+1}.` ,
            });
            setTimeout(() => {
              setMessage(null);
            }, 6500);
            return;
          }
        }

        if(applicationData.questions[k].maxWords){
          if(answers[k].wordCount > applicationData.questions[k].maxWords){
            setMessage({
              variant: "danger",
              data: `Please write less than ${applicationData.questions[k].maxWords} words for question ${k+1}.` ,
            });
            setTimeout(() => {
              setMessage(null);
            }, 6500);
            return;
          }
        }
      }

      const appData = {
        appName: applicationData.name,
        submittedBy: props.auth.user.id,
        answers: answers,
        parentId: applicationData._id,
        tag: tag,
      };
      axios.post("/applications/submitApplication", appData)
      .then((res) => {
          if(res.status === 200){
            history.push("/applications", {message: "Application Submitted Successfully!", variant: "success"})
          }         
      })
      .catch((err) => {
          setError(err.response.data);
          if (err.response.data.answers) {
            setMessage({
              variant: "danger",
              data: err.response.data.answers,
            });
            setTimeout(() => {
              setMessage(null);
            }, 6500);
          }
      })
    }

    const setAnswer = (e, index) => {
      const {name, value} = e.target;
      if(value === "[Select]"){
        return;
      }

      const wordList = value.split(" ")
      let updatedCount = 0
      wordList.forEach((word) => {
        if(word.length > 0){
          updatedCount++
        }
      });
      const list = [...answers];
      list[index].answer = value;
      list[index].wordCount = updatedCount;
      setAnswers(list);
    }

    const mapChoices = (index) => {
      let choiceStack = [];
      //console.log(applicationData.questions[index]);
      for(let k = 0; k < applicationData.questions[index].choices.length; k++){
        let ans = <option>{applicationData.questions[index].choices[k].option}</option>
        choiceStack.push(ans);
      }
      return choiceStack;
    }

    const returnToApps = () => {
      history.push("/applications");
    }

    const getCheckBoxes = (i) => {
      const list = [];
      for(let k = 0; k < applicationData.questions[i].choices.length; k++){
        let temp =  <Form.Check
                      type="checkbox"
                      label={applicationData.questions[i].choices[k].option}
                      onChange={(e) => {
                        setCheckAns(e, i, k, applicationData.questions[i].choices[k].option);
                      }}
                    />
        list.push(temp);

      }
      return list;
    }

    const setCheckAns = (e, i, k, val) => {
      const list = [...answers];

      let tempStr = k > 0 ? "answer" + k : "answer";
      var place = list[i];
      
      if(e.target.checked){
        place[tempStr] = val;
      }else{
        place[tempStr] = "";
      }

      list[i] = place;

      setAnswers(list);
    }

    return (
        <div className="App">
          <Container>
            { isLoading
                ? (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" />
                  </div>
                  ) : (
                    
                    <div className="UserApplication">
                      <Container>
                      <h2 className="display-2 text-center">{applicationData.name}</h2>
                      <p>{parse(applicationData.description)}</p>
                      {message && <Alert variant={message.variant}>{message.data}</Alert>}
                      <Form noValidate onSubmit={onSubmit}>

                        {Object.keys(applicationData.questions).map((q, index) => 
                            <React.Fragment>
                                {applicationData.questions[index].type == "short-answer" || applicationData.questions[index].type == "" ? (
                                    <React.Fragment>
                                        <Form.Group key={index} className="mb-3">
                                            <Form.Label>
                                            <br></br>
                                            <b>Question {index+1}: {applicationData.questions[index].q_prompt}</b>
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="8"
                                                required
                                                id={index}
                                                placeholder="Answer"
                                                onChange={(e) => setAnswer(e, index)}
                                                value={answers[index].answer}
                                                tabIndex={1}
                                                isInvalid={error.name}
                                                className={classnames("", {
                                                    invalid: error.name,
                                                })}
                                            />
                                            <Row>
                                              {applicationData.questions[index].minWords && applicationData.questions[index].minWords > 0 ?(
                                                <Col classNames="col-md-5">
                                                
                                                <Form.Label>
                                                  <b> Minimum Words Required:</b> {applicationData.questions[index].minWords} 
                                                </Form.Label>
                                              
                                              </Col>
                                              ):(
                                                <React.Fragment>
                                                </React.Fragment>
                                              )
                                              }
                                              {applicationData.questions[index].maxWords && applicationData.questions[index].maxWords > 0 ?(
                                                <Col classNames="col-md-5">
                                                
                                                <Form.Label>
                                                  <b> Maximum Words Required:</b> {applicationData.questions[index].maxWords} 
                                                </Form.Label>
                                              
                                              </Col>
                                              ):(
                                                <React.Fragment>
                                                </React.Fragment>
                                              )
                                                
                                              }
                                              
                                                <Col classNames="col-md-2">
                                                  
                                                    <Form.Label>
                                                      <b> Your Words:</b> {answers[index].wordCount} 
                                                    </Form.Label>
                                                  
                                                </Col>

                                            </Row>

                                            <Form.Control.Feedback type="invalid">
                                                {error.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        
                                    </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {applicationData.questions[index].type == "check-boxes" ? (
                                      <React.Fragment>
                                      <Form.Group key={index} className="mb-3">
                                          <Form.Label>
                                          <br></br>
                                          <b>Question {index+1}: {applicationData.questions[index].q_prompt}</b>
                                          </Form.Label>
                                          {getCheckBoxes(index)}
                                          <Form.Control.Feedback type="invalid">
                                              {error.name}
                                          </Form.Control.Feedback>
                                      </Form.Group>
                                  </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                        <Form.Group key={index} className="mb-3">
                                            <Form.Label>
                                            <br></br>
                                            <b>Question {index+1}: {applicationData.questions[index].q_prompt}</b>
                                            </Form.Label>
                                              <Form.Select
                                                    aria-label="answers"
                                                    value={answers[index].answer}
                                                    onChange={(e) => {
                                                      setAnswer(e, index);
                                                    }}
                                                >
                                                <option>[Select]</option>
                                                
                                                {mapChoices(index)}
                                                

                                              </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                {error.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </React.Fragment>
                                  )}
                                    </React.Fragment>
                                )}
                                
                                
                            </React.Fragment>

                        )}
                        <div>
                        <br></br>
                        
                        {!preview ? (
                          <div style={{float: 'right'}}>
                              <Button variant="primary" className="submit-btn" type="submit">
                                  Submit Application
                              </Button>
                              <br></br><br></br>
                          </div>
                        ) : (
                          <div style={{float: 'right'}}>
                              <Button variant="primary" onClick={() => returnToApps()}>
                                  Return to Applications
                              </Button>
                              <br></br><br></br>
                          </div>
                          
                        )
                          
                        }
                        
                        
                        </div>
                    </Form>

                      </Container>
                    </div>
                  )
            } 
          </Container>
        </div>
    );
}

UserApplication.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(UserApplication);