import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Spinner, Table, Alert, Row, Col, Button, Input, Form} from "react-bootstrap";
import MyEvents from "../Events/MyEvents";
import { useHistory, Route, Link, useParams} from "react-router-dom";
import RangeSlider from 'react-bootstrap-range-slider';
import classnames from "classnames";
import axios from "axios";
import React from "react";
import "./appStyles.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

function ReviewingApp(props) {
    // State Management
    const [isLoading, setLoading] = useState(true);
    const [applicationData, setApplicationData] = useState({});
    const [parentApplicationData, setParentApplicationData] = useState({});
    const [message, setMessage] = useState();
    const [error, setError] = useState({});
    const [showUpdateAccepted, setShowUpdateAccepted] = useState(false);
    const [numberAccepted, setNumberAccepted] = useState(0);
    const [modalMessage, setModalMessage] = useState();
    const [reviewScore, setReviewScore] = useState([]);
    const [isLoading2, setLoading2] = useState(true);
    const [reviews, setReviews] = useState([]);



    const history = useHistory();
    const appID = history.location.state.appData._id;
    const parentID = history.location.state.appData.parentId;
    const reviewResult = history.location.state.result;
    const editingApplication = history.location.state.editingApplication;

    useEffect(() => {
        axios.get("/applications/getApplicationById", {
            params:
              {
                userID: props.auth.user.id,
                appID: appID,
              }
          })
              .then((res) => {
                  setApplicationData(res.data[0]);
                  loadScores(res.data[0]);
                  setLoading2(false);
              })
              .catch((err) => console.log(err));

        axios.get("/applications/getApplicationById", {
            params:
              {
                userID: props.auth.user.id,
                appID: parentID,
              }
          })
              .then((res) => {
                  setParentApplicationData(res.data[0]);
                  setLoading(false);
                  
              })
              .catch((err) => console.log(err));
        
        axios.get("/applications/getReviewsForApplication", {
            params:
            {
                appID: appID,
            }
        })
        .then((res) => {
            setReviews(res.data);
        })

    }, []);

    const loadScores = (data) => {
        const list = [];
        if(!editingApplication){
            for(let k = 0; k < data.answers.length; k++){
                list.push({score: "0", comment:""});
            }
        }else{
            const reviewerData = data.reviewers.find((r) => r.reviewedBy == props.auth.user.id);
            for(let k = 0; k < reviewerData.review.length; k++){
                list.push({score: reviewerData.review[k].score, comment: reviewerData.review[k].comment});
            }
        }
        setReviewScore(list);
    }

    const setComment = (e, index) => {
        const list = [...reviewScore];
        list[index].comment = e;
        setReviewScore(list);
    }

    const setResponseScore = (e, index) => {
        const list = [...reviewScore];
        list[index].score = e;
        setReviewScore(list);
    }

    const getFormattedAnswer = (str) => {
        // console.log(str)
        const strArr = str.split("\n");
        const ans = [];
        strArr.forEach((s) => {
            let temp =  <React.Fragment>
                            <p>{s}</p>
                        </React.Fragment>
            ans.push(temp);
        })
        return ans;
    }
    
    const getQuestionsAndAnswers = () => {
        let ans = [];
        for(let k = 0; k < parentApplicationData.questions.length; k++){
            let temp = <h5><b>Question {k+1}: {parentApplicationData.questions[k].q_prompt}</b></h5>
            let temp3 = <React.Fragment>
                            {parentApplicationData.questions[k].graded == true &&
                                <React.Fragment>
                                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}><p>Question {k+1} Score: {reviewScore[k].score}</p></div>
                                    <RangeSlider value={reviewScore[k].score} min="0" max="10" onChange={(e) => setResponseScore(e.target.value, k)} />
                                    <br></br>
                                </React.Fragment>
                            }
                            <Form.Control
                                as="textarea"
                                rows="3"
                                required
                                id={k}
                                placeholder="Add Comment"
                                onChange={(e) => setComment(e.target.value, k)}
                                value={reviewScore[k].comment}
                                tabIndex={1}
                                isInvalid={error.name}
                                className={classnames("", {
                                    invalid: error.name,
                                })}
                            />
                            <br></br><br></br><br></br>
                        </React.Fragment>
            let temp4 = <React.Fragment>
                            <Table bordered hover className="text-center">
                                <thead>
                                    <tr>
                                        <th>Reviewer</th>
                                        <th>Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getReviews(k, parentApplicationData.questions[k].graded)}
                                </tbody>
                            </Table>
                            <br></br>
                        </React.Fragment>
            ans.push(temp);
            Object.keys(applicationData.answers[k]).forEach((str) => {
                console.log(str)
                if(str === "answer"){
                    let tempPlace = applicationData.answers[k].answer;
                    ans.push(getFormattedAnswer(tempPlace))
                }else if(str.includes("answer")){
                    let tempPlace = applicationData.answers[k];
                    ans.push(getFormattedAnswer(tempPlace[str]))
                }
                
            });
            
            if(!reviewResult){
                ans.push(temp3);
            }else {
                ans.push(temp4);
            }
            
        }

        return <div>{ans}</div>
    }

    const getReviews = (ques, b) => {
        const ans = [];
        let k = 0;
        let avg = 0;
        const colors = {
            0: "table-secondary",
            1: "table-default",
        };

        for(let i = 0; i < reviews.length; i++){
            let tempTarget = (".commentRow" + i) + ques;
            let tempClassName = ("collapse commentRow" + i) + ques;
            let temp =  <React.Fragment>
                            {b ? (
                                <React.Fragment>
                                        <tr className={colors[i % 2]} data-toggle="collapse" data-target={tempTarget} style={{cursor:'pointer'}}>
                                            <td class="col-md-2">{reviews[i].reviewerName}</td>
                                            <td class="col-md-2">{reviews[i].review[ques].score}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" className="hiddenRow"><div className={tempClassName}>{reviews[i].review[ques].comment}</div></td>
                                        </tr>
                                </React.Fragment>
                            ): (
                                <React.Fragment>
                                    <tr className={colors[i % 2]} data-toggle="collapse" data-target={tempTarget} style={{cursor:'pointer'}}>
                                        <td class="col-md-2">{reviews[i].reviewerName}</td>
                                        <td class="col-md-2">Ungraded Question</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" className="hiddenRow"><div className={tempClassName}>{reviews[i].review[ques].comment}</div></td>
                                    </tr>
                                </React.Fragment>
                            )}
                        </React.Fragment>
            
            avg += parseInt(reviews[i].review[ques].score);
            ans.push(temp);
        }
        avg /= reviews.length;
        let temp =  <tr>
                        <td class="col-md-2"><b>Average</b></td>
                        <td class="col-md-2">{avg}</td>
                    </tr>
        ans.push(temp);
        return ans;
    }

    const mapTable = () =>{
        const ans = [];
        let total = 0;
        let len = 0;
        for(let k = 0; k < reviewScore.length; k++){
            if(parentApplicationData.questions[k].graded){
                total += parseInt(reviewScore[k].score);
                len++;
                let temp =  <tr>
                                <td class="col-md-2">Question {k+1}</td>
                                <td class="col-md-2">{reviewScore[k].score}</td>
                            </tr>
                ans.push(temp);
            }else{
                let temp =  <tr>
                                <td class="col-md-2">Question {k+1}</td>
                                <td class="col-md-2">Ungraded Question</td>
                            </tr>
                ans.push(temp);
            }
        }
        let temp =  <tr>
                        <td class="col-md-2"><b>Total</b></td>
                        <td class="col-md-2">{total}</td>
                    </tr>
        let average = total / len;
        let temp2 =  <tr>
                        <td class="col-md-2"><b>Average</b></td>
                        <td class="col-md-2">{average}</td>
                    </tr>
        ans.push(temp);
        ans.push(temp2);
        return ans;
    }

    const submitReviewEdit = () => {
        const spot = applicationData.reviewers.findIndex((r) => {return r.reviewedBy == props.auth.user.id});
        axios.post("/applications/submitReviewEdit", {
            params:{
                appID: appID,
                review: reviewScore,
                reviewBy: props.auth.user.id,
                spot: spot,
            }
        }).then((res) => {
            if(res.status === 200){
                history.push("/reviewapplication/" + parentID, {message: "Review updated successfully!", variant: "success"});
            }
        })
    }

    const submitReview = () => {
        axios.post("/applications/submitReview", {
            params:{
                appID: appID,
                review: reviewScore,
                reviewBy: props.auth.user.id
            }
        }).then((res) => {
            if(res.status === 200){
                history.push("/reviewapplication/" + parentID);
            }
        })
    }

    const returnToReview = () => {
        history.push("/reviewapplication/" + parentID);
    }


    return (
        <div className="ReviewingApp">
            <React.Fragment>
                <Container>
                    {
                        isLoading || isLoading2
                            ? (
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" />
                                </div>
                            )
                            : (
                                <React.Fragment>
                                    <h2 className="display-2 text-center">{applicationData.name}</h2>
                                    <h4 className="display-8 text-center">Tag: {applicationData.tag}</h4>
                                    {message && <Alert variant={message.variant}>{message.data}</Alert>}
                                    <br></br>
                                    {getQuestionsAndAnswers()}
                                    <br></br>
                                    {!reviewResult && 
                                        <React.Fragment>
                                            <h2 className="display-2 text-center">Review Scoring:</h2>
                                            
                                            <Table striped bordered hover className="text-center">
                                                <thead>
                                                    <tr>
                                                        <th>Question</th>
                                                        <th>Score</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {mapTable()}
                                                </tbody>
                                            </Table>

                                            <br></br>
                                        
                                        {!editingApplication ? (
                                            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                                <Button variant="primary btn-lg" className="add-choice-btn" onClick={() => submitReview()}>
                                                    Submit Review!
                                                </Button>
                                            </div>
                                        )  : (
                                            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                                <Button variant="primary btn-lg" className="add-choice-btn" onClick={() => submitReviewEdit()}>
                                                    Update Review!
                                                </Button>
                                            </div>
                                        )
                                            
                                        }
                                        
                                    </React.Fragment>
                                    }
                                    
                                    {reviewResult &&
                                        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                        <Button variant="primary btn-lg" className="add-choice-btn" onClick={() => returnToReview()}>
                                            Return to Results
                                        </Button>
                                    </div>
                                    }
                                    <br></br><br></br>
                                </React.Fragment>
                            )
                    }
                </Container>
            </React.Fragment>
        </div>
    );
}

ReviewingApp.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(ReviewingApp);
